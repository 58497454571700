// kynection colors

// primary

$light-primary: #2a286b;
$light-primary-contrast: #ffffff;

$dark-primary: #2a286b;
$dark-primary-contrast: #ffffff;

// accent/secondary

$light-accent: #f5bf19;
$light-accent-contrast: #000000;

$dark-accent: #f5bf19;
$dark-accent-contrast: #000000;

// common

$page-background: #2a286b2f;
